var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_c('label', {
    attrs: {
      "for": `label-${_vm.label || _vm.validationLabel}`
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.computedLabel)
    }
  }), _vm._t("labelMessage")], 2), _c('b-form-file', {
    staticClass: "file-input-custom",
    attrs: {
      "readonly": _vm.readonly,
      "accept": _vm.computedMimeTypes,
      "multiple": "",
      "plain": ""
    },
    model: {
      value: _vm.computedInputData,
      callback: function ($$v) {
        _vm.computedInputData = $$v;
      },
      expression: "computedInputData"
    }
  }), _vm._t("uploadedFiles"), _vm.vObject && _vm.vObject.$error ? [_c('span', {
    staticClass: "error-text"
  }, [_vm._v(_vm._s(_vm.computedErrorMessage))])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }