<template>
  <div>
    <div
      class="custom-border-bottom py-3"
      v-if="row && row.issue_comment && row.issue_comment.length"
    >
      <h5 class="main-text mb-3">Comments</h5>
      <div class="comment-container">
        <vue-scroll :ops="scrollOptions">
          <div class="comment-wrap">
            <div v-for="(comment, i) in row.issue_comment" :key="i">
              <div
                class="comment-box w-100"
                :class="[
                  comment.admin_reply ? 'bg-clr-lightgrey' : 'bg-clr-lightblue',
                ]"
              >
                <span
                  :class="[comment.admin_reply ? 'arrow-left' : 'arrow-right']"
                ></span>
                <div class="d-flex">
                  <div
                    class="icon-image split justify-content-center"
                    v-if="comment.admin_reply"
                  >
                    <i class="fa fa-user text-white"></i>
                  </div>
                  <div class="user-image" v-else>
                    <img :src="userImage" alt="" class="img-fluid" />
                  </div>
                  <div class="ml-3 flex-grow-1">
                    <div class="split">
                      <h6 class="main-text mb-3">
                        {{ comment.admin_reply ? "Admin" : name }}
                      </h6>
                      <span class="font-13 mb-2">
                        {{ comment.created_at }}
                      </span>
                    </div>
                    <div class="ql-snow">
                      <div class="ql-editor">
                        <p v-html="comment.comment"></p>
                      </div>
                    </div>
                    <file-list
                      v-if="comment.document.length"
                      :files="comment.document"
                      :link-class="['bg-white']"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import FileList from "./FileList.vue";
import UserImg from "@/assets/images/default_profile.jpg";
export default {
  props: {
    row: {
      required: true,
      default: () => null,
    },
  },
  components: {
    FileList,
  },
  computed: {
    name() {
      return this.$store.getters.user.name;
    },
    userImage() {
      return this.$store.getters.user.image || UserImg;
    },
    scrollOptions() {
      return {
        vuescroll: {
          mode: "native",
          wheelScrollDuration: "700",
        },
        scrollPanel: {
          maxHeight: 500,
          speed: 2000,
        },
        bar: {
          background: "#ddd",
        },
      };
    },
  },
};
</script>

<style>
</style>