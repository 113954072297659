<template>
  <div class="py-3">
    <h5 class="main-text mb-3">Write a comment</h5>
    <form @submit.prevent="submit">
      <input-quill-editor
        :vmodel.sync="form.comment"
        validation-label="comment"
        :rules="{ required: true }"
        placeholder="Write a comment ..."
        :vObject="$v.form.comment"
        form-group-class="col-md-12 px-0 mb-3"
      />
      <div>
        <div class="attach-file d-flex bg-clr-lightgrey py-1">
          <label class="btn btn-default">
            <input
              type="file"
              hidden
              multiple
              id="file_input"
              @change="handleFileUpload($event)"
            />
            <span v-b-tooltip.hover title="Upload Files"
              ><i class="fas fa-paperclip clr-bold-grey"></i
            ></span>
          </label>
          <ul
            v-if="form.comment_document.length"
            class="list-unstyled m-0 p-2 d-flex flex-wrap"
          >
            <li v-for="(file, i) in form.comment_document" :key="i">
              <span class="bg-white badge-custom font-13"
                >{{ file.name
                }}<span
                  class="close-file"
                  v-b-tooltip.hover
                  title="Remove File"
                  @click="removeFile(i)"
                >
                  <i class="fa fa-times file-remove-icon"></i> </span
              ></span>
            </li>
          </ul>
        </div>
        <span class="d-inline-block font-14"
          >Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv &
          txt upto 4Mb</span
        >
      </div>
      <button
        type="submit"
        class="comment-submit-btn btn-no-focus"
        :class="{ isDisabled: !row.status }"
        :disabled="!row.status"
      >
        Add Comment
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import helper from "@/resource/Helper";
import InputQuillEditor from "@/components/form/InputQuillEditor.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    InputQuillEditor,
  },
  props: {
    row: {
      required: true,
    },
  },
  data: () => ({
    form: {
      comment: "",
      comment_document: [],
    },
  }),
  validations: {
    form: {
      comment: {
        required,
      },
    },
  },
  methods: {
    handleFileUpload(e) {
      // check for type error
      this.form.comment_document = [];
      let uploads;
      uploads = e.target.files;
      if (uploads && uploads.length) {
        for (let i = 0; i < uploads.length; i++) {
          this.form.comment_document.push(uploads[i]);
        }
      }
    },
    removeFile(i) {
      this.form.comment_document.splice(i, 1);
    },
    submit() {
      if (!this.isFormValid()) {
        return;
      }
      let loader = this.$loading.show();
      let url = `${process.env.VUE_APP_TICKETING_API}/api/v1/issue/comment`;
      let formData = new FormData();
      const form = { issue_id: this.row.id, ...this.form };
      for (const [key, value] of Object.entries(form)) {
        if (key == "comment_document") {
          for (let i = 0; i < form.comment_document.length; i++) {
            formData.append(`${key}[${i}]`, form.comment_document[i]);
          }
        } else {
          formData.append(key, value);
        }
      }
      axios
        .post(url, formData)
        .then((res) => {
          helper.infoMessage(this, "success", res.data.message);
          this.form = {
            comment: "",
            comment_document: [],
          };
          this.$emit("onSuccess", this.row);
        })
        .catch((err) => {
          helper.infoMessage(this, "error", err.response.data.message);
        })
        .finally(() => {
          loader.hide();
        });
    },
    isFormValid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      return true;
    },
  },
  watch: {
    row: {
      handler: function (row) {
        if (row) {
          this.$v.$reset();
        }
      },
    },
  },
};
</script>

<style scoped>
.isDisabled {
  cursor: not-allowed;
}
.file-remove-icon {
  width: 9px !important;
  margin: 0 !important;
}
.badge-custom {
  color: #0cf;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 4px 6px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
</style>