var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ticket-card"
  }, [Object.keys(_vm.row).length ? _c('div', {
    staticClass: "ticket-card-body"
  }, [_c('div', {
    staticClass: "single-ticket hover-bg-none"
  }, [_c('div', {
    staticClass: "pb-3 custom-border-bottom"
  }, [_c('div', {
    staticClass: "split mb-3"
  }, [_c('h2', {
    staticClass: "main-text"
  }, [_vm._v("Title: " + _vm._s(_vm.row.title))]), _c('div', {
    staticClass: "ticket-action"
  }, [_c('div', [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "dropright": "",
      "id": "ticket-dropdown",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": "Action"
          }
        }, [_c('i', {
          staticClass: "fa fa-ellipsis-v"
        })])];
      },
      proxy: true
    }], null, false, 923793288)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.handleTicketAction(_vm.row);
      }
    }
  }, [_vm._v(_vm._s(_vm.row.status ? "Close" : "Re-open") + " Ticket")])], 1)], 1)])]), _c('p', {
    staticClass: "ticket-date mb-2"
  }, [_c('i', {
    staticClass: "far fa-calendar-alt",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.row.created_at) + " ")]), _c('div', {
    staticClass: "ql-snow"
  }, [_c('div', {
    staticClass: "ql-editor"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.row.details)
    }
  })])])]), _c('ticket-dcouments', {
    attrs: {
      "row": _vm.row
    }
  }), _c('ticket-comments', {
    attrs: {
      "row": _vm.row
    }
  }), _c('add-commnet', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "onSuccess": _vm.onSuccess
    }
  })], 1)]) : _c('p', {
    staticClass: "no-data"
  }, [_vm._v("Please click on the issue to view details")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }