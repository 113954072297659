var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ticket-card"
  }, [_vm._t("ticket-card-header"), _vm.rows && _vm.rows.records && _vm.rows.records.length ? _c('div', {
    staticClass: "ticket-card-body"
  }, [_vm._l(_vm.rows.records, function (row, i) {
    return _c('div', {
      key: i,
      staticClass: "custom-border-bottom hover-bg-grey position-relative cursor-pointer ticket-component",
      attrs: {
        "id": `ticket-${i}`
      },
      on: {
        "click": function ($event) {
          return _vm.handleIssueClick(row, `ticket-${i}`);
        }
      }
    }, [_c('div', {
      staticClass: "single-ticket-wrap d-flex"
    }, [_c('div', {
      staticClass: "ticket-details"
    }, [_c('h3', [_vm._v(_vm._s(_vm.formattedTitle(row.title)))]), _c('p', {
      staticClass: "ticket-summary mb-2"
    }, [_vm._v(_vm._s(_vm.strip(row.details)))]), _c('p', {
      staticClass: "ticket-date"
    }, [_c('i', {
      staticClass: "far fa-calendar-alt",
      attrs: {
        "aria-hidden": "true"
      }
    }), _vm._v(" " + _vm._s(row.created_at) + " ")]), _c('div', {
      staticClass: "ticket-tags"
    }, [_c('span', {
      staticClass: "ticket-badge clr-white bg-clr-primary me-2"
    }, [_vm._v(_vm._s(row.category.name))]), _c('span', {
      class: ['ticket-badge font-14 text-uppercase', row.status ? 'is-opened' : 'is-closed']
    }, [_c('i', {
      staticClass: "fa fa-circle icon-margin-e"
    }), _vm._v(_vm._s(row.status ? "Opened" : "Closed"))])])]), _c('span', {
      staticClass: "font-14 comment-icon"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/comment.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(row.issue_comment_count) + " ")])])]);
  }), _vm._t("pagination")], 2) : _c('p', {
    staticClass: "no-data"
  }, [_vm._v("No Records Found")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }