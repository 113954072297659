var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_vm.label ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.label)), _vm.rules && _vm.rules.required ? _c('span', {
    staticClass: "input-required"
  }, [_vm._v(" *")]) : _vm._e()]) : _vm._e(), _c('quill-editor', {
    class: [_vm.vObject && _vm.vObject.$error ? 'quill-invalid-border' : '', _vm.inputClass],
    attrs: {
      "options": _vm.computedEditorOptions
    },
    model: {
      value: _vm.computedVmodel,
      callback: function ($$v) {
        _vm.computedVmodel = $$v;
      },
      expression: "computedVmodel"
    }
  }), _vm.vObject && _vm.vObject.$error ? [_c('span', {
    staticClass: "error-text"
  }, [_vm._v(_vm._s(_vm.computedErrorMessage))])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }