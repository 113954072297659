var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_vm.label ? [_c('label', {
    attrs: {
      "for": `label-${_vm.labelFor || _vm.validationLabel}`
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.computedLabel)
    }
  })])] : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.computedInputData,
      expression: "computedInputData"
    }],
    class: [_vm.vObject && _vm.vObject.$error ? 'has-error' : '', 'form-control', _vm.inputClass],
    attrs: {
      "id": `label-${_vm.labelFor || _vm.validationLabel}`,
      "placeholder": _vm.computedPlaceholder
    },
    domProps: {
      "value": _vm.computedInputData
    },
    on: {
      "blur": _vm.handleBlur,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.computedInputData = $event.target.value;
      }
    }
  }), _vm.vObject && _vm.vObject.$error ? [_c('span', {
    staticClass: "error-text"
  }, [_vm._v(_vm._s(_vm.computedErrorMessage))])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }