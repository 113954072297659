var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid px-0"
  }, [_c('div', {
    staticClass: "ticket-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "row pt-0"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('ticket-list', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filters
    },
    on: {
      "update:rows": function ($event) {
        _vm.rows = $event;
      },
      "issueClick": _vm.getTicketDetail
    },
    scopedSlots: _vm._u([{
      key: "ticket-card-header",
      fn: function () {
        return [_c('div', {
          staticClass: "split ticket-card-header custom-border-bottom"
        }, [_c('h3', {
          staticClass: "main-text"
        }, [_vm._v("All Tickets")]), _c('button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "add-ticket-icon split btn-no-focus",
          attrs: {
            "title": "Add Ticket",
            "id": "add-ticket-btn "
          },
          on: {
            "click": _vm.openForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })])])];
      },
      proxy: true
    }, {
      key: "pagination",
      fn: function () {
        return [_c('pagination', {
          staticClass: "ticket-pagination",
          attrs: {
            "rows": _vm.rows,
            "show-page-record": false,
            "filters": _vm.filters
          },
          on: {
            "update:rows": function ($event) {
              _vm.rows = $event;
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "col-md-8"
  }, [!_vm.showForm ? _c('ticket-detail', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "update:row": function ($event) {
        _vm.row = $event;
      },
      "onSuccess": _vm.handleSuccess,
      "handleTicketAction": _vm.handleTicketAction
    }
  }) : _vm._e(), _vm.showForm ? _c('ticket-form', {
    on: {
      "onSuccess": _vm.getTickets
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ticket-page-title"
  }, [_c('h3', [_vm._v("Tickets")])]);

}]

export { render, staticRenderFns }