<template>
  <ul class="list-unstyled m-0 p-0">
    <li v-for="(file, i) in files" :key="i">
      <a
        :href="file.file_url"
        target="_blank"
        class="
          ticket-badge
          clr-primary
          hover-clr-primary
          py-2
          mb-2
        "
        :class="[...linkClass]"
        v-b-tooltip.hover
        :title="`${
          showFileSize ? `File Size : ${file.file_size} ${file.size_unit}` : ''
        }`"
        ><i class="fa fa-file-alt icon-margin-e"></i>{{ file.file_name }}</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: "FileList",
  props: {
    files: {
      required: true,
    },
    showFileSize: {
      required: false,
      default: false,
    },
    linkClass: {
      required: false,
      default: () => ['bg-clr-light'],
    },
  },
};
</script>