var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-3"
  }, [_c('h5', {
    staticClass: "main-text mb-3"
  }, [_vm._v("Write a comment")]), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('input-quill-editor', {
    attrs: {
      "vmodel": _vm.form.comment,
      "validation-label": "comment",
      "rules": {
        required: true
      },
      "placeholder": "Write a comment ...",
      "vObject": _vm.$v.form.comment,
      "form-group-class": "col-md-12 px-0 mb-3"
    },
    on: {
      "update:vmodel": function ($event) {
        return _vm.$set(_vm.form, "comment", $event);
      }
    }
  }), _c('div', [_c('div', {
    staticClass: "attach-file d-flex bg-clr-lightgrey py-1"
  }, [_c('label', {
    staticClass: "btn btn-default"
  }, [_c('input', {
    attrs: {
      "type": "file",
      "hidden": "",
      "multiple": "",
      "id": "file_input"
    },
    on: {
      "change": function ($event) {
        return _vm.handleFileUpload($event);
      }
    }
  }), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Upload Files"
    }
  }, [_c('i', {
    staticClass: "fas fa-paperclip clr-bold-grey"
  })])]), _vm.form.comment_document.length ? _c('ul', {
    staticClass: "list-unstyled m-0 p-2 d-flex flex-wrap"
  }, _vm._l(_vm.form.comment_document, function (file, i) {
    return _c('li', {
      key: i
    }, [_c('span', {
      staticClass: "bg-white badge-custom font-13"
    }, [_vm._v(_vm._s(file.name)), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "close-file",
      attrs: {
        "title": "Remove File"
      },
      on: {
        "click": function ($event) {
          return _vm.removeFile(i);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times file-remove-icon"
    })])])]);
  }), 0) : _vm._e()]), _c('span', {
    staticClass: "d-inline-block font-14"
  }, [_vm._v("Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv & txt upto 4Mb")])]), _c('button', {
    staticClass: "comment-submit-btn btn-no-focus",
    class: {
      isDisabled: !_vm.row.status
    },
    attrs: {
      "type": "submit",
      "disabled": !_vm.row.status
    }
  }, [_vm._v(" Add Comment ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }