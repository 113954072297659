import { render, staticRenderFns } from "./AddComment.vue?vue&type=template&id=6f2399aa&scoped=true&"
import script from "./AddComment.vue?vue&type=script&lang=js&"
export * from "./AddComment.vue?vue&type=script&lang=js&"
import style0 from "./AddComment.vue?vue&type=style&index=0&id=6f2399aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2399aa",
  null
  
)

export default component.exports