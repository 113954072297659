var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "list-unstyled m-0 p-0"
  }, _vm._l(_vm.files, function (file, i) {
    return _c('li', {
      key: i
    }, [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "ticket-badge clr-primary hover-clr-primary py-2 mb-2",
      class: [].concat(_vm.linkClass),
      attrs: {
        "href": file.file_url,
        "target": "_blank",
        "title": `${_vm.showFileSize ? `File Size : ${file.file_size} ${file.size_unit}` : ''}`
      }
    }, [_c('i', {
      staticClass: "fa fa-file-alt icon-margin-e"
    }), _vm._v(_vm._s(file.file_name))])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }