<template>
  <div :class="formGroupClass">
    <b-form-group>
      <label :for="`label-${label}`" v-if="label">
        <span v-html="computedLabel" />
      </label>
      <multiselect
        v-model="computedInputData"
        :options="options"
        :custom-label="customLabel"
        :track-by="trackBy"
        @focus.native="autoCompleteHandler"
        @input="inputHandler"
        :placeholder="computedPlaceholder"
        :disabled="isDisabled"
        :deselectLabel="deselectLabel"
        :selectedLabel="selectedLabel"
        :selectLabel="selectLabel"
        :class="[
          vObject && vObject.$error ? 'multiselect-error' : '',
          inputClass,
        ]"
      >
      </multiselect>
    </b-form-group>
    <template v-if="vObject && vObject.$error">
      <span class="error-text">{{ computedErrorMessage }}</span>
    </template>
  </div>
</template>
<script>
import ErrorMessage from "./error_message";
export default {
  name: "InputSingleSelect",
  props: {
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    label: {
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    vmodel: {
      required: true,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      required: true,
    },
    customLabel: {
      required: false,
    },
    isDisabled: {
      required: false,
      default: false,
    },
    optionsLimit: {
      type: Number,
      default: 300,
    },
    trackBy: {
      type: String,
      required: false,
    },
    validationLabel: {
      type: String,
      required: false,
    },
    deselectLabel: {
      type: String,
      default: "",
    },
    selectedLabel: {
      default: "",
    },
    selectLabel: {
      type: String,
      default: "",
    },
    vObject: {
      required: false,
    },
  },
  computed: {
    computedLabel() {
      if (!this.label) {
        return "";
      }
      if (this.rules && this.rules.required) {
        return this.label + "<span class='input-required'>  *</span>";
      }
      return this.label;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function (value) {
        if (this.vObject) {
          this.vObject.$touch();
        }
        this.$emit("update:vmodel", value);
      },
    },
    errorField() {
      return this.apiErrors ? this.apiErrors[this.apiErrorKey] : null;
    },
    computedPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      return `Select ${this.label || "options"} `;
    },
    computedErrorMessage() {
      let errorMessage = new ErrorMessage(
        this.vObject,
        this.label || this.validationLabel
      ).generateMessage();
      return errorMessage[0];
    },
  },
  methods: {
    inputHandler() {
      this.$emit("onInput");
    },
    selectClassName(validationContext) {
      let className = "";
      if (this.rules) {
        if (validationContext.errors[0]) {
          className = "invalid-input-block";
        } else if (validationContext.valid) {
          className = "valid-input-block";
        } else {
          className = "";
        }
      }
      return `${className} ${this.inputClass}`;
    },
    autoCompleteHandler() {
      document
        .querySelector(".multiselect__input")
        .setAttribute("autocomplete", "off");
    },
  },
};
</script>
<style>
.multiselect__option--selected.multiselect__option--highlight {
  background: #00ccff !important;
}
.input-required {
  color: rgb(255, 0, 0) !important;
}
.error-text {
  display: block;
  margin-top: 4px;
  color: #dc3545;
  font-size: 14px;
}
.multiselect-error .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
.multiselect__option--highlight{
  color:#fff !important;
}
</style>