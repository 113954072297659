var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_c('b-form-group', [_vm.label ? _c('label', {
    attrs: {
      "for": `label-${_vm.label}`
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.computedLabel)
    }
  })]) : _vm._e(), _c('multiselect', {
    class: [_vm.vObject && _vm.vObject.$error ? 'multiselect-error' : '', _vm.inputClass],
    attrs: {
      "options": _vm.options,
      "custom-label": _vm.customLabel,
      "track-by": _vm.trackBy,
      "placeholder": _vm.computedPlaceholder,
      "disabled": _vm.isDisabled,
      "deselectLabel": _vm.deselectLabel,
      "selectedLabel": _vm.selectedLabel,
      "selectLabel": _vm.selectLabel
    },
    on: {
      "input": _vm.inputHandler
    },
    nativeOn: {
      "focus": function ($event) {
        return _vm.autoCompleteHandler.apply(null, arguments);
      }
    },
    model: {
      value: _vm.computedInputData,
      callback: function ($$v) {
        _vm.computedInputData = $$v;
      },
      expression: "computedInputData"
    }
  })], 1), _vm.vObject && _vm.vObject.$error ? [_c('span', {
    staticClass: "error-text"
  }, [_vm._v(_vm._s(_vm.computedErrorMessage))])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }