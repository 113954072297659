var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.row && _vm.row.document && _vm.row.document.length ? _c('div', {
    staticClass: "custom-border-bottom py-3"
  }, [_c('h5', {
    staticClass: "main-text mb-3"
  }, [_vm._v("Attachments")]), _c('file-list', {
    attrs: {
      "files": _vm.row.document,
      "show-file-size": true
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }