<template>
  <div :class="formGroupClass">
    <label for="" v-if="label"
      >{{ label
      }}<span class="input-required" v-if="rules && rules.required">
        *</span
      ></label
    >
    <quill-editor
      v-model="computedVmodel"
      :options="computedEditorOptions"
      :class="[
        vObject && vObject.$error ? 'quill-invalid-border' : '',
        inputClass,
      ]"
    />
    <template v-if="vObject && vObject.$error">
      <span class="error-text">{{ computedErrorMessage }}</span>
    </template>
  </div>
</template>
<script>
import ErrorMessage from "./error_message";
export default {
  name: "InputQuillEditor",
  props: {
    vmodel: {
      required: true,
    },
    label: {
      required: false,
      default: "",
    },
    placeholder: {
      required: false,
      default: "",
    },
    formGroupClass: {
      required: false,
      default: "col-md-12 mb-3",
    },
    rules: {
      type: Object,
      required: false,
      default: () => {},
    },
    validationLabel: {
      required: false,
      default: "",
    },
    editorOptions: {
      required: false,
      default: null,
    },
    inputClass:{
      required: false
    },  
    vObject:{
      required:false
    }
  },
  data: () => ({}),
  computed: {
    computedVmodel: {
      get: function () {
        return this.vmodel;
      },
      set: function (value) {
        if (this.vObject) {
          this.vObject.$touch();
        }
        this.$emit("update:vmodel", value ? value : "");
      },
    },
    computedEditorOptions() {
      if (this.editorOptions) {
        return this.editorOptions;
      }
      const placeholder = this.placeholder
        ? this.placeholder
        : "Enter Description ...";
      return {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }, { header: 3 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image"],
          ],
          imageResize: {
            //Add
            displayStyles: {
              //Add
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
        placeholder: placeholder,
      };
    },
    computedErrorMessage() {
      let errorMessage = new ErrorMessage(
        this.vObject,
        this.label || this.validationLabel
      ).generateMessage();
      return errorMessage[0];
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    getInputClass(validationContext) {
      let className = "";
      if (validationContext.errors[0]) {
        className = "is-invalid";
      } else if (validationContext.valid) {
        className = "is-valid";
      } else {
        className = "";
      }
      return `${className}`;
    },
  },
};
</script>

<style>
.ql-container {
  min-height: 220px;
}
.quill-editor.quill-invalid-border .ql-container {
  border: 1px solid #dc3545 !important;
}
.quill-editor.quill-invalid-border .ql-toolbar {
  border-bottom: 0;
}
.error-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.input-required {
  color: red !important;
}
</style>
